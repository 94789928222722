import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import HeadFooterContext from '@/context/HeaderFooterContext';
import MapCordinateContext from '@/context/MapCordinateContext';
import { facilityUrl } from '@/helper/filterlogicInfo';
import { getConvenientLocations } from '@/services/home';
import { DEFAULT_FACILITY_ID } from '@/utils/constants';
import defaultCenters from '@/utils/default';

import CallbackModal from '../callback-modal/requestcallback-modal';
import StarsComponent from '../stars';

const ConvenientLocations = (props: any) => {
  const [facilityId, setFacilityId] = useState('');
  const [unitRate, setUnitRate] = useState('');
  const [unitDimensions, setUnitDimensions] = useState('');

  const handleCallbackClick = (unitData: any) => {
    if (unitData?.lcode) setFacilityId(unitData?.lcode);
    else setFacilityId(DEFAULT_FACILITY_ID);
    setUnitRate(unitData?.rate);
    setUnitDimensions(unitData?.size);
  };
  const { locale, asPath }: any = useRouter();
  const { setCordinateData } = useContext(MapCordinateContext);
  const { setConvenientLocations } = useContext(HeadFooterContext);
  const [convenientLocationsData, setconvenientLocationsData] = useState<any>(
    []
  );

  const getCovenientData = (latitude: any, longitude: any) => {
    getConvenientLocations(locale, { latitude, longitude }, 3)
      .then((res) => res)
      .then((data) => {
        setconvenientLocationsData(data?.data);
        const location = data && data.data && data.data[0];
        setConvenientLocations(location);
        if (data?.data?.length === 0) {
          const { lat } = defaultCenters[2].center;
          const long = defaultCenters[2].center.lng;
          setCordinateData({ latitude, longitude });
          getCovenientData(lat, long);
        }
      });
  };

  const clearSessionStorage = () => {
    if (sessionStorage.getItem('facCords')) {
      sessionStorage.removeItem('facCords');
    }
    const previousRou = { path: asPath, locale };
    if (locale && asPath)
      sessionStorage.setItem('prevRoute', JSON.stringify(previousRou));
  };

  useEffect(() => {
    // Naviagtor function handles success call back for allow and block for reject call back.
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCordinateData({ latitude, longitude });
        getCovenientData(latitude, longitude);
      },
      () => {
        const latitude = defaultCenters[2].center.lat;
        const longitude = defaultCenters[2].center.lng;
        setCordinateData({ latitude, longitude });
        getCovenientData(latitude, longitude);
      }
    );
  }, [locale]);

  const facilityUrlHrefCovenientLocation = (loclist: any) => {
    let splicedStr;
    let url = '/';

    if (locale === 'fr') {
      if (loclist.location.length > 1) {
        url = `/fr/entreposage-libre-service/${loclist.location[0]}/${loclist.location[1]}`;
      } else {
        url = `/fr/entreposage-libre-service/${loclist.location[0]}`;
      }
    } else if (loclist.location.length > 1) {
      [, splicedStr] = loclist.location;
      url = `/en/self-storage/${loclist.location[0]}/${splicedStr}`;
    } else {
      url = `/en/self-storage/${loclist.location[0]}`;
    }
    return url;
  };
  const address = (item: any) => {
    const lastindex = item?.address?.lastIndexOf(',');
    return item?.address?.slice(0, lastindex) || '';
  };

  return (
    <section className="convenient-locations">
      <div className="container">
        {props.display ? (
          <h2 className="section-heading">
            <FormattedMessage id="convenient" />
          </h2>
        ) : (
          <></>
        )}
        <div className="location-cards d-none d-lg-block d-md-none">
          <div className="row justify-content-center g-3">
            {convenientLocationsData &&
              convenientLocationsData?.map((item: any) => {
                const loc = facilityUrl(item);
                return (
                  <div
                    key={item?.lcode}
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                  >
                    <div className="card sv-location-card">
                      <div>
                        <Image
                          src={item?.image}
                          loading="lazy"
                          className="card-img-top"
                          alt={item?.slug || 'location-img'}
                          width="350"
                          height="175"
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 540px, (max-width: 768px) 355px, (max-width: 992px) 310px, (max-width: 1280px) 370px"
                        />
                      </div>

                      <div className="card-body">
                        <h4 className="location-name">{item?.title}</h4>
                        <h6 className="address">{address(item)}</h6>
                        <div className="ratings d-flex">
                          <FormattedMessage id="reviews" /> &nbsp;
                          <StarsComponent data={item?.star}></StarsComponent>
                        </div>
                        <p className="card-text">
                          <FormattedMessage id="start-from" />{' '}
                          <span>${item?.rate}</span>{' '}
                          <FormattedMessage id="per-week" />
                        </p>
                        <a
                          href="#"
                          className="primary-card-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#requestCallbackModal"
                          onClick={() => {
                            handleCallbackClick(item);
                          }}
                        >
                          <FormattedMessage id="call-back" />
                        </a>

                        <Link
                          href={facilityUrlHrefCovenientLocation(loc)}
                          passHref
                        >
                          <a
                            className="secondary-card-btn ms-2"
                            onClick={clearSessionStorage}
                          >
                            <FormattedMessage id="view-units" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* Tablet */}
        <div className="location-cards d-none d-lg-none d-md-block">
          <div className="row justify-content-center g-3">
            {convenientLocationsData &&
              convenientLocationsData?.map((item: any, index: any) => {
                const loc = facilityUrl(item);
                return (
                  index < 2 && (
                    <div
                      key={item?.lcode}
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    >
                      <div className="card sv-location-card">
                        <div>
                          <Image
                            src={item?.image}
                            sizes="(max-width: 375px) 300px, (max-width: 576px) 540px, (max-width: 768px) 355px, (max-width: 992px) 310px, (max-width: 1280px) 370px"
                            loading="lazy"
                            className="card-img-top"
                            alt={item?.slug || 'location-img'}
                            width="350"
                            height="175"
                          />
                        </div>

                        <div className="card-body">
                          <h4 className="location-name">{item?.title}</h4>
                          <h6 className="address">{address(item)}</h6>
                          <div className="ratings d-flex">
                            <FormattedMessage id="reviews" /> &nbsp;
                            <StarsComponent data={item?.star}></StarsComponent>
                          </div>
                          <p className="card-text">
                            <FormattedMessage id="start-from" />{' '}
                            <span>${item?.rate}</span>{' '}
                            <FormattedMessage id="per-week" />
                          </p>
                          <a
                            href="#"
                            className="primary-card-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#requestCallbackModal"
                            onClick={() => {
                              handleCallbackClick(item);
                            }}
                          >
                            <FormattedMessage id="call-back" />
                          </a>

                          <Link
                            href={facilityUrlHrefCovenientLocation(loc)}
                            passHref
                          >
                            <a
                              className="secondary-card-btn ms-2"
                              onClick={clearSessionStorage}
                            >
                              <FormattedMessage id="view-units" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>

        {/* Mobile */}
        <div className="location-cards d-block d-lg-none d-md-none">
          <div className="row justify-content-center g-3">
            {convenientLocationsData &&
              convenientLocationsData?.map((item: any, index: any) => {
                const loc = facilityUrl(item);
                return (
                  index === 0 && (
                    <div
                      key={item?.lcode}
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    >
                      <div className="card sv-location-card">
                        <div>
                          <Image
                            src={item?.image}
                            sizes="(max-width: 375px) 300px, (max-width: 576px) 540px, (max-width: 768px) 355px, (max-width: 992px) 310px, (max-width: 1280px) 370px"
                            loading="lazy"
                            className="card-img-top"
                            alt={item?.slug || 'location-img'}
                            width="350"
                            height="175"
                          />
                        </div>

                        <div className="card-body">
                          <h4 className="location-name">{item?.title}</h4>
                          <h6 className="address">{address(item)}</h6>
                          <div className="ratings d-flex">
                            <FormattedMessage id="reviews" /> &nbsp;
                            <StarsComponent data={item?.star}></StarsComponent>
                          </div>
                          <p className="card-text">
                            <FormattedMessage id="start-from" />{' '}
                            <span>${item?.rate}</span>{' '}
                            <FormattedMessage id="per-week" />
                          </p>
                          <a
                            href="#"
                            className="primary-card-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#requestCallbackModal"
                            onClick={() => {
                              handleCallbackClick(item);
                            }}
                          >
                            <FormattedMessage id="call-back" />
                          </a>
                          <Link
                            href={facilityUrlHrefCovenientLocation(loc)}
                            passHref
                          >
                            <a
                              className="secondary-card-btn ms-2"
                              onClick={clearSessionStorage}
                            >
                              <FormattedMessage id="view-units" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <CallbackModal
          ero={false}
          facilityId={facilityId}
          unitRate={unitRate}
          unitDimensions={unitDimensions}
          formType="callmeback"
          storeType="ss"
        ></CallbackModal>
      </div>
    </section>
  );
};

export default ConvenientLocations;
